import React from "react";
import { useNavigate } from "react-router-dom";

import LogoImg from "../assets/images/hyatt-logo.svg";

const Hyattlogo = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="Logo">
        <img src={LogoImg} alt="Grand Hyatt" onClick={()=>navigate("/")}/>
      </div>
    </>
  );
};
export default Hyattlogo;
