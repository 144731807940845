import React from "react";
import { Link } from "react-router-dom";
import BG from "./assets/images/hotelBG.webp";
import fnf from "./assets/images/fnf.png";

const Error404 = () => {
  return (
    <div
      className="Bgpic Hotel"
      style={{
        backgroundImage: `url(${BG}`,
      }}
    >
      <div className="error-box">
       <img src={fnf} />
        <h3>Oops! Page not found!</h3>
        <p>The page you requested was not found.</p>
        <Link to="/" className="btn">
            Back to Home
        </Link>
      </div>
    </div>
  );
};

export default Error404;
