import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowForward } from "@mui/icons-material";
import {
  Button,
  Modal,
  Box,
  TextField,
  Typography,
  FormControl,
} from "@mui/material";
import { CircularProgress } from "@mui/material";

import baseUrl from "../utils";
import fetchApi from "../Components/fetchApi";
import Hyattvideo from "./hyattvideo";
import Hyattlogo from "./hyattlogo";
import useLongPress from "../Components/useLongPress";
import landingVideo from "../assets/Hyatt Welcome (1).mp4";

export default function Home() {
  const navigate = useNavigate();
  const [videos, setvideos] = useState([]);
  const token = localStorage.getItem("token");
  const [sharePopUp, setSharePopUp] = useState(false);
  const [shareLinkBody, setShareLinkBody] = useState({ name: "", email: "" });
  const [apiCall, setApiCall] = useState(false);
  const [successMsg, setSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
 
  const params = useParams();

  const getVideo = () => {
    axios
      .get(baseUrl("video/"), {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setvideos(res.data);
        localStorage.setItem("videos", JSON.stringify(res.data));
      })
      .catch((err) => {
        const video = JSON.parse(localStorage.getItem("videos"));
        setvideos(video);
      });
  };

  const onLongPress = () => {
    setSharePopUp(true);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 300,
  };
  const longPressEvent = useLongPress(onLongPress, defaultOptions);

  useEffect(() => {
    //verify token
    const tokenvalidation = () => {
      axios.get(baseUrl(`share/${params.id}`)).then((response) => {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", response.data.user.role);
        setApiCall(true);
      });
    };
    if (params.id) {
      tokenvalidation();
    }
  }, [params.id]);

  useEffect(() => {
    getVideo();
    fetchApi.fetchRoomsApi(token);
    fetchApi.fetchDiningApi(token);
    fetchApi.fetchEventsApi(token);
    fetchApi.fetchRecreationalApi(token);
    fetchApi.fetchDestinationApi(token);
  }, [apiCall]);

  const handleChange = (e) => {
    setShareLinkBody({ ...shareLinkBody, [e.target.name]: e.target.value });
  };

  const sendLink = (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");
    axios
      .post(baseUrl("share/"), shareLinkBody, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        setSuccessMsg(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(error.response.data.email);
      });
  };


  return (
    <>
      <div {...longPressEvent}>
        <Hyattlogo />
        {/* {videos
          ?.filter((ele) => ele.screen === "HOME")
          .map((src) => {
            return <Hyattvideo videoSource={src.file} />;
          })} */}
          <Hyattvideo videoSource={landingVideo} click={true}/>
      </div>
      <div className="GoBtn">
        <div>
          <button onClick={() => navigate("/welcome")}>
            GO
            <br />
            <ArrowForward />
          </button>
        </div>
      </div>
      <Modal
        open={sharePopUp}
        onClose={() => {
          setSharePopUp(false);
          setError("");
        }}
        className="userPopup"
      >
        <Box>
          {loading ? (
            <div className="Loading">
              <CircularProgress />
            </div>
          ) : (
            <form onSubmit={sendLink}>
              <FormControl>
                <TextField
                  required
                  placeholder="Name"
                  name="name"
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <TextField
                  required
                  placeholder="Email"
                  name="email"
                  type="email"
                  onChange={handleChange}
                />
              </FormControl>
              <Button type="submit">Send</Button>
              {error && (
                <p style={{ color: "maroon", fontSize: "20px" }}>{error}</p>
              )}
            </form>
          )}
        </Box>
      </Modal>
      <Modal
        open={successMsg}
        onClose={() => setSuccessMsg(false)}
        className="userPopup"
      >
        <Box>
          <Typography>You have successfully shared the link!</Typography>
          <Button onClick={() => setSuccessMsg(false)}>OK</Button>
        </Box>
      </Modal>
    </>
  );
}
