import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./pages/login";
import Home from "./pages/home";
import PrivateRoute from "./Components/privaterouter";
import { lazyComponent } from "./Components/lazyloading";
import Error404 from "./fallback";

const Hotel = React.lazy(() => import("./pages/hotel/hotel"));
const Rooms = React.lazy(() => import("./pages/hotel/rooms/rooms"));
const RoomsType = React.lazy(() => import("./pages/hotel/rooms/RoomsType"));
const Dining = React.lazy(() => import("./pages/hotel/Dining/dining"));
const Diningdetails = React.lazy(() =>
  import("./pages/hotel/Dining/diningDetails")
);
const Events = React.lazy(() => import("./pages/hotel/events"));
const Eventdetails = React.lazy(() => import("./pages/hotel/eventDetails"));
const Recreationals = React.lazy(() => import("./pages/hotel/recreational"));
const Recreationaldetails = React.lazy(() =>
  import("./pages/hotel/recreationalDetails")
);
const Destination = React.lazy(() => import("./pages/destination/destination"));
const Video = React.lazy(() => import("./pages/hotel/video"));
const Photo = React.lazy(() => import("./pages/hotel/photo"));
const Welcome = React.lazy(() => import("./pages/welcome"));

function App() {
  const [userRole,setUserRole] = useState("")
  window.localStorage.removeItem("room");
  window.localStorage.removeItem("recreation");
  window.localStorage.removeItem("diningstatus");
  window.localStorage.removeItem("localevent");
  window.addEventListener("contextmenu", e => e.preventDefault());

useEffect(()=>{
  setInterval(()=>{
    setUserRole(localStorage.getItem("user"))
  },1000)
},[userRole])

  return (
    <div>
      <Router>
        <Routes>
          {
            userRole !== "admin" && userRole !== null ?
            <Route path="/:id" element={<Home />}/>
          :
          ""
          }
          <Route exact path="*" element={<Error404/>} />
          <Route exact path="/" element={<PrivateRoute childComponent={<Home />} />}/>
          <Route exact path="/welcome" element={<PrivateRoute childComponent={lazyComponent(Welcome)}/>} />
          <Route exact path="/hotel" element={<PrivateRoute childComponent={lazyComponent(Hotel)}/>} />
          <Route exact path="/destination" element={<PrivateRoute childComponent={lazyComponent(Destination)}/>} />
          <Route exact path="/rooms" element={<PrivateRoute childComponent={lazyComponent(Rooms)}/>} />
          <Route exact path="/roomsType" element={<PrivateRoute childComponent={lazyComponent(RoomsType)}/>} />
          <Route exact path="/dining" element={<PrivateRoute childComponent={lazyComponent(Dining)}/>} />
          <Route exact path="/diningDetails" element={<PrivateRoute childComponent={lazyComponent(Diningdetails)}/>} />
          <Route exact path="/recreational" element={<PrivateRoute childComponent={lazyComponent(Recreationals)}/>} />
          <Route exact path="/recreationalDetails" element={<PrivateRoute childComponent={lazyComponent(Recreationaldetails)}/>} />
          <Route exact path="/events" element={<PrivateRoute childComponent={lazyComponent(Events)}/>} />
          <Route exact path="/eventsDetails" element={<PrivateRoute childComponent={lazyComponent(Eventdetails)}/>} />
          <Route exact path="/photo" element={<PrivateRoute childComponent={lazyComponent(Photo)}/>} />
          <Route exact path="/video" element={<PrivateRoute childComponent={lazyComponent(Video)}/>} />
          <Route exact path="/login" element={<Login />} />
        </Routes>
      </Router>
      {/* <Suspense fallback={<div>Loading...</div>}>
        <OtherComponent />
      </Suspense> */}
    </div>
  );
}
export default App;
