// import { CircularProgress } from "@mui/material";
import React, { useState, useRef, useEffect, memo } from "react";
import speakeron from "../assets/images/audio-speaker.png";
import speakeroff from "../assets/images/volume.png";
import logo from "../assets/images/hyatt-logo.png";
import wlecomeVideo from "../assets/Destination Video.mp4";
import landingVideo from "../assets/Hyatt Welcome (1).mp4";

const Hyattvideo = ({ videoSource, click }) => {
  const [speaker, setSpeaker] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [videoUrl, setVideoUrl] = useState(null);
  const video = useRef(null);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // part1, part2, part3, part4, part5, part6, part7

  const unmuteHandler = () => {
    console.log("video", video);
    if (video) {
      setSpeaker((prev) => !prev);
      video.current.muted = !video.current.muted;
      setIsActive((current) => !current);
      console.log("show");
    }
    setTimeout(() => {
      setIsActive((current) => !current);
      console.log("Hide");
    }, 2000);
  };
  // const handleProgress = (e) => {
  //   if (isNaN(e.target.duration))
  //     // duration is NotaNumber at Beginning.
  //     return;
  //   setProgress((e.target.currentTime / e.target.duration) * 100);
  // };

  // useEffect(() => {
  //   async function fetchVideo() {
  //     const cache = await caches.open("Hyatt-app");
  //     const response = await cache.match(videoSource);
  //     if (response) {
  //       const blob = await response.blob();
  //       const url = URL.createObjectURL(blob);
  //       setVideoUrl(url);
  //     } else {
  //       // fetch the video from the server
  //       const response = await fetch(videoSource);
  //       const blob = await response.blob();
  //       const url = URL.createObjectURL(blob);
  //       setVideoUrl(url);
  //     }
  //   }

  //   fetchVideo();
  // }, []);

  // useEffect(() => {
  //   console.log("video>>", video.current.src);
  //   var req = new XMLHttpRequest();
  //   req.open("GET", videoSource, true);
  //   req.responseType = "blob";

  //   req.onload = function (res) {
  //     // Onload is triggered even on 404
  //     // so we need to check the status code
  //     if (res.currentTarget.status === 200) {
  //       var videoBlob = res.currentTarget.response;
  //       var vid = URL.createObjectURL(videoBlob); // IE10+
  //       // Video is now downloaded
  //       // and we can set it as source on the video element
  //       video.current.src = vid;
  //       console.log("videoBlob>>", vid);
  //       console.log("video>>", video.current);
  //     }
  //   };
  //   req.onerror = function () {
  //     // Error
  //     console.log("erorr");
  //   };

  //   req.send();

  //   video.onwaiting = (event) => {
  //     console.log("Video is waiting for more data.");
  //     setIsVideoLoaded(false);
  //   };
  // }, [videoSource]);
  // const playVideo = () => {
  //   if (video.current.readyState >= 3) {
  //     video.current.play();
  //   }
  // };

  return (
    <>
      {!isVideoLoaded && (
        <div className="loading">
          <img src={logo} className="logo-loading" alt="logo" />
          {/* <CircularProgress
          color="warning"
          /> */}
        </div>
      )}
      <div
        className="videoBG"
        onTouchStart={click && unmuteHandler}
        onClick={unmuteHandler}
      >
        <div className="DotBG">
          <img
            src={speaker ? speakeron : speakeroff}
            style={{
              opacity: isActive ? ".4" : "0",
            }}
            className="audio"
            alt="speaker"
          />
        </div>
        {videoSource && (
          <video
            loop
            muted
            autoPlay
            playsInline
            id={"video"}
            src={videoSource}
            preload={"auto"}
            type={"video/mp4"}
            ref={video}
            onLoadedData={() => setIsVideoLoaded(true)}
            // onTimeUpdate={handleProgress}
            onCanPlayThrough={() => setIsVideoLoaded(true)}
            onLoad={() => setIsVideoLoaded(true)}
            // onLoadedMetadata={playVideo}
          />
        )}
        {/* <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/ZQPIOsKpZlo?controls=0&autoplay=1&loop=1"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe> */}
      </div>
    </>
  );
};
export default Hyattvideo;
