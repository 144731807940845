import axios from "axios";

import baseUrl from "../utils";


const fetchRoomsApi = (token, setRooms, setLoading) => {
  axios
    .get(baseUrl("rooms"), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((res) => {
      localStorage.setItem("rooms", JSON.stringify(res.data));

    })
    .catch((err) => {
      console.log(err);
    });
};

const fetchDiningApi = (token) => {
  axios
    .get(baseUrl("dinings"), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((response) => {
      localStorage.setItem("dining", JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

const fetchEventsApi = (token) => {
  axios
    .get(baseUrl("events_list"), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((response) => {
      localStorage.setItem("events", JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

const fetchRecreationalApi = (token) => {
  axios
    .get(baseUrl("recreationals"), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((response) => {
      localStorage.setItem("recreational", JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

const fetchDestinationApi = (token) => {
  axios
    .get(baseUrl("destination"), {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`,
      },
    })
    .then((response) => {
      localStorage.setItem("destination", JSON.stringify(response.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

const FetchApi = {
  fetchRoomsApi,
  fetchDiningApi,
  fetchEventsApi,
  fetchRecreationalApi,
  fetchDestinationApi,
};

export default FetchApi;
