import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import EmailIcon from "@mui/icons-material/Email";
import LoginIcon from "@mui/icons-material/Login";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import LogoImg from "../assets/images/logo192.png";

import baseUrl from "../utils";

export default function Login() {
  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState({ username: "", password: "" });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
    setError(false);
  };

  const passwordToggle = () => {
    setVisible(!visible);
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(baseUrl("login/"), inputValue)
      .then((res) => {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", res.data.user.role);
        navigate("/");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
        if (error.message === "Request failed with status code 400") {
          setErrorMsg("please enter valid email and password");
        } else {
          console.log("hiiiiii");
          setErrorMsg("please check your internet connection and try again");
        }
      });
  };

  return (
    <div className="loginDiv">
      <div className="loginContent">
        <img src={LogoImg} alt="hyatt-logo" />
        <form onSubmit={handleLogin}>
          {error && <p style={{ color: "maroon" }}>{errorMsg}</p>}
          <FormControl>
            <div>
              <span>
                <EmailIcon fontSize="medium" />
              </span>
              <TextField
                required
                id="username"
                name="username"
                type="text"
                placeholder="Username"
                onChange={handleChange}
              />
            </div>
          </FormControl>
          <FormControl>
            <div>
              <span>
                <LockIcon fontSize="medium" />
              </span>
              <TextField
                required
                id="password"
                name="password"
                placeholder="Password"
                type={visible ? "text" : "password"}
                onChange={handleChange}
              />
              <span onClick={passwordToggle}>
                {visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </span>
            </div>
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            color="secondary"
            endIcon={!loading ? <LoginIcon /> : ""}
            disabled={loading}
          >
            {loading ? "Logging In" : "Log In"}
          </Button>
        </form>
      </div>
    </div>
  );
}
